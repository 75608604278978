@import "@styles/variables";

.wrap-day {
    .react-calendar {
        // contain: strict;
        width: 100%;
        background: $white-color;
        border: 1px solid #a0a096;
        font-family: Roboto, Arial, Helvetica, sans-serif;
        line-height: 1.125em;
        border: 0px solid #a0a096;
        border-radius: 14px;
        color: $text-color;

        .react-calendar__month-view__days__day {
            color: $text-color;
        }
        .react-calendar__tile--active {
            background-color: #297952b7;
            color: #fff !important;
        }
        .react-calendar__month-view__days__day--weekend {
            color: $text-color;
        }
        .react-calendar__month-view__days__day--neighboringMonth {
            color: #c7c7c7;
        }

        .react-calendar__navigation {
            display: flex;
            padding-top: 10px;
            height: initial;
            box-sizing: border-box;
            margin-bottom: 0;
        }
        .react-calendar__navigation__label {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: initial;
            padding-bottom: 5px;
            color: #274335;
            &:hover {
                background-color: transparent;
            }
        }
        .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
            display: flex;
            width: 1px;
            padding: 0;
            &:hover {
                background-color: transparent;
            }
        }
        .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            box-sizing: border-box;
            margin-left: 30px;
            &:hover {
                background-color: #fff;
            }
        }
        .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
            display: flex;
            width: 1px;
            padding: 0;
            &:hover {
                background-color: transparent;
            }
        }
        .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 30px;
            box-sizing: border-box;
            &:hover {
                background-color: transparent;
            }
        }

        .calendar-date {
            text-align: center;
        }
        abbr[title] {
            text-decoration: none;
        }

        .react-calendar__month-view__weekdays__weekday {
            color: #c7c7c7;
            font-size: 14px;
            text-decoration: none !important;
            max-width: 40px;
            @media screen and (max-width: 700px) {
                font-size: 12px;
            }
        }
        .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
            font-size: 18px;
            font-weight: 700;
            font-family: "Roboto";
        }
        .react-calendar__month-view__days {
            gap: 0px 18px;
            @media screen and (max-width: 700px) {
                gap: 0px 7px;
            }
        }

        .react-calendar__tile.react-calendar__month-view__days__day.content {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 33px;
            min-height: 33px;
            padding: 0;
            border-radius: 50%;
        }
        .content {
            font-size: 16px;
        }
        .react-calendar__year-view .react-calendar__tile,
        .react-calendar__decade-view .react-calendar__tile,
        .react-calendar__century-view .react-calendar__tile {
            padding: 1.2em 0.5em;
            height: inherit;
            &:hover {
                border-radius: 20px;
            }
        }
        .react-calendar__tile--hasActive {
            border-radius: 20px;
            width: 50px;
            background: #a1b6ad;
        }
        .react-calendar__tile--now:enabled {
            background: #a1b6ad;
            color: white;
        }

        .react-calendar__tile--active:enabled:hover,
        .react-calendar__tile--active:enabled:focus {
            background: #a1b6ad;
        }
        .react-calendar__tile:enabled:visited,
        .react-calendar__tile:enabled:focus {
            background: #297952b7;
            color: white;
        }
        .react-calendar__month-view__weekdays {
            gap: 10px;
            @media screen and (max-width: 700px) {
                gap: 0px;
            }
        }
    }
}
