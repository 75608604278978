@import "@styles/variables";

.check-wrapper {
  display: flex;
  justify-self: "start";
  width: "100%";
  // padding-top: 7px;

  .check-input {
    position: absolute;
    opacity: 0;
    width: 1.25em;
    height: 1.25em;
    margin: 0;
    cursor: pointer;
    &:checked + .check::before {
      background: url("../../assets/icons/Vector.svg");
      background-position: center;
    }
    &:hover + .check::before {
      border: 1px solid $button-disabled-color;
    }
  }
  .check {
    display: inline-flex;
    align-items: center;
    user-select: none;
    &:before {
      content: "";
      display: inline-block;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid $button-hover-color;
      border-radius: 2px;
      background-color: $white-color;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      margin-right: 8px;
    }
  }
}
