@import "@styles/variables";

.modal-filter {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 15px;
  padding: 20px;
  width: 100%;
  @media screen and (max-width: "1200px") {
    width: 100%;
  }
  @media screen and (max-width: "700px") {
    padding: 0;
    gap: 15px;
  }

  div {
    box-sizing: border-box;
  }
  .modal-filter-select {
    display: flex;
    width: 100%;
  }
  h1 {
    align-self: center;
    margin: 0;
    font-size: 26px;
    font-weight: 400;
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 14px;
    .line-input {
      display: grid;
      grid-template-columns: 0.7fr 2fr;
      align-items: center;
      box-sizing: border-box;
      gap: 10px;
      @media screen and (max-width: "700px") {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 5px;
      }
      span {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .line {
    display: flex;
    height: 1px;
    background-color: $button-disabled-color;
  }
  .calendar-wrap {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: $text-color;
    gap: 10px;
    button {
      min-height: 40px;
    }

    .cal-item {
      display: flex;
      flex: 2;
      gap: 10px;

      .left-label-up {
        display: flex;
        width: 35%;
        align-items: center;
      }
      .left-label {
        display: flex;
        width: 35%;
        align-items: center;
        @media screen and (max-width: "700px") {
          width: 0;
        }
      }
      .data-picker {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        .select {
          padding: 12px 10px;
          height: 43px;
        }
      }
      .select-cal-body {
        display: flex;
        flex-direction: column;
        width: 100%;

        .react-calendar {
          contain: strict;
          width: 100%;
          background: #f1f6f6;
          border: 1px solid #a0a096;
          font-family: Roboto, Arial, Helvetica, sans-serif;
          line-height: 1.125em;
          border: 0px solid #a0a096;
          border-radius: 14px;
          color: $text-color;

          .react-calendar__month-view__days__day {
            color: $text-color;
          }
          .react-calendar__tile--active {
            background-color: #a1b6ad;
            color: #fff !important;
          }
          .react-calendar__month-view__days__day--weekend {
            color: $text-color;
          }
          .react-calendar__month-view__days__day--neighboringMonth {
            color: #c7c7c7;
          }

          .react-calendar__navigation {
            display: flex;
            padding-top: 10px;
            height: initial;
            margin-bottom: 0;
          }
          .react-calendar__navigation__label {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: initial;
            padding-bottom: 5px;
            color: #274335;
            &:hover {
              background-color: transparent;
            }
          }
          .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
            display: flex;
            width: 1px;
            padding: 0;
            &:hover {
              background-color: transparent;
            }
          }
          .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px !important;
            height: 40px;
            border-radius: 50%;
            box-sizing: border-box;
            margin-left: 80px;
            padding: 0 15px;
            &:hover {
              background-color: #fff;
            }
          }
          .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
            display: flex;
            width: 1px;
            padding: 0;
            &:hover {
              background-color: transparent;
            }
          }
          .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 80px;
            box-sizing: border-box;
            padding: 0 15px;
            &:hover {
              background-color: transparent;
            }
          }

          .calendar-date {
            text-align: center;
          }
          abbr[title] {
            text-decoration: none;
          }

          .react-calendar__month-view__weekdays__weekday {
            color: #c7c7c7;
            font-size: 14px;
            text-decoration: none !important;
            max-width: 73px;
          }
          .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
            font-size: 18px;
            font-weight: 700;
            font-family: "Roboto";
          }
          .react-calendar__month-view__days {
            padding-left: 20px;
            gap: 10px 40px;
            @media screen and (max-width: "700px") {
              gap: 10px 7px;
              padding-left: 7px;
            }
          }

          .react-calendar__tile.react-calendar__month-view__days__day.content {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 40px;
            height: 40px;
            padding: 0;
            border-radius: 50%;
          }
          .content {
            font-size: 18px;
          }
        }

        .clear {
          color: #ff3b30;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
  button {
    width: 100%;
    text-align: center;
    color: $white-color;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    font-weight: 700;
  }
  .react-calendar__month-view__weekdays {
    gap: 8px !important;
    @media screen and (max-width: "700px") {
      gap: 0 !important;
    }
  }
}
