@import "@styles/variables";

.choose-admin {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 28px 30px 20px 30px;
  width: 600px;
  @media screen and (max-width: "1200px") {
    width: 100%;
  }
  @media screen and (max-width: "700px") {
    padding: 0;
    gap: 15px;
  }
  h1 {
    align-self: center;
    text-align: center;
    margin: 0;
    font-size: 26px;
    font-weight: 400;
  }
  .filter-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    height: 43px;
    min-width: 250px;
    padding: 4px 18px 4px 7px;
    background-color: $white-color;
    border: 1px solid $button-disabled-color;
    border-radius: 10px;
    box-sizing: border-box;
    div {
      box-sizing: border-box;
    }
    input {
      width: 100%;
      background: none;
      outline: none;
      border: none;
      font-size: 18px;
      font-weight: 400;
      color: $text-color;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .list-wrap {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 300px;
    overflow-y: auto;
    border: 1px solid $button-disabled-color;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .list-item {
      display: flex;
      align-items: center;
      padding: 10px 10px;
      min-height: 46px;
      font-size: 16px;
      border-bottom: 1px solid $button-disabled-color;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        background-color: $button-disabled-color;
      }
    }
  }
}
