@import "@styles/variables";

.table-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
  min-width: 810px;
  overflow-x: auto;
  div {
    box-sizing: border-box;
  }

  .table {
    display: flex;
    flex-direction: column;
    gap: 1px;
    margin-bottom: 2px;
    flex: 1;

    &-header {
      display: grid;
      grid-template-columns: 7% 12% 25% 16% 12% 12% 5% 4% 3%;
      grid-template-rows: 35px;
      gap: 3px;
      align-items: center;
      background-color: $white-color;
      padding: 4px 0px 4px 0;

      &.supervisor {
        grid-template-columns: 10% 14% 23% 18% 18% 17%;
      }
      div {
        justify-self: start;
        color: $text-table-color;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex: 1;
      max-height: calc(100vh - 177px);
      overflow-y: auto;
      position: relative;
      background-color: $white-color;
      border: 1px solid $button-disabled-color;
      scrollbar-color: $scroll-color $select-hover-color;
      scrollbar-width: thin;
      min-height: 300px;

      &.supervisor {
        max-height: calc(100vh - 125px);
      }
      .row {
        display: grid;
        min-height: 77px;
        grid-template-columns: 7% 12% 25% 16% 12% 12% 5% 4% 3%;
        gap: 3px;
        align-items: center;
        position: relative;
        font-size: 16px;
        color: $text-color;
        &.supervisor {
          grid-template-columns: 10% 14% 23% 18% 16% 16%;
        }
        span {
          padding-right: 11px;
        }
        .icons {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          padding: 0 14px;
          .favor {
            display: flex;
            align-items: center;
            justify-self: center;
            img {
              width: 40px;
              height: 40px;
            }
          }
          .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            img {
              width: 40px;
              height: 40px;
            }
          }
        }
        .user-item {
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          padding: 0 10px 0 0;
          .field-name {
            display: flex;
            align-items: center;
            width: 100%;
            height: 16px;
            &:hover {
              font-weight: 700;
            }
          }
          .tooltip {
            display: flex;
            flex-direction: column;
            position: absolute;
            right: 45px;
            top: -17px;
            border-radius: 10px;
            box-sizing: border-box;
            overflow: hidden;
            background-color: $white-color;
            scale: 0;
            transition: all 0.4s;
            -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
            box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
            &.info {
              right: inherit;
              left: 20px;
              top: -60px;
              width: 220px;
              transition: all 0.4s;
            }
            &.active {
              scale: 1;
              transition: all 0.4s;
            }
            &-item {
              display: flex;
              justify-items: center;
              align-items: center;
              gap: 15px;
              box-sizing: border-box;
              width: 267px;
              height: 43px;
              padding: 6px 10px 6px 14px;
              &:hover {
                background-color: $select-hover-color;
              }
            }
          }
        }

        span {
          text-align: left;
        }
        .check-input {
          position: absolute;
          opacity: 0;
          width: 1.25em;
          height: 1.25em;
          margin: 0;
          cursor: pointer;
          &:checked + .check::before {
            background: url("../../../../assets/icons/Vector.svg");
            background-position: center;
          }
          &:hover + .check::before {
            border: 1px solid $button-disabled-color;
          }
        }
        .check {
          display: inline-flex;
          align-items: center;
          user-select: none;

          &:before {
            content: "";
            display: inline-block;
            width: 1em;
            height: 1em;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1px solid $button-hover-color;
            border-radius: 2px;
            background-color: $white-color;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
          }
        }
        .dates {
          display: flex;
          flex-direction: column;
        }
        .tooltip {
          display: flex;
          flex-direction: column;
          position: absolute;
          right: 45px;
          top: -17px;
          border-radius: 10px;
          box-sizing: border-box;
          overflow: hidden;
          background-color: $white-color;
          scale: 0;
          transition: all 0.4s;
          -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
          -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
          box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
          &.info {
            right: 340px;
            top: -15px;
            width: 200px;
          }
          &.active {
            scale: 1;
            transition: all 0.4s;
          }
          &-item {
            display: flex;
            justify-items: center;
            align-items: center;
            gap: 15px;
            box-sizing: border-box;
            width: 267px;
            height: 43px;
            padding: 6px 10px 6px 14px;
            &:hover {
              background-color: $select-hover-color;
            }
          }
        }
        .set-menu {
          display: flex;
          width: 22px;
          cursor: pointer;
          justify-content: center;
          position: relative;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .table-wrapper .table-body .row {
    font-size: 14px;
  }
}

@media screen and (max-width: 870px) {
  .table-wrapper {
    width: calc(100vw - 50px);
    overflow-x: auto;
    min-width: inherit;
    @media screen and (max-width: 700px) {
      width: 100vw;
    }
  }
  .table {
    width: 1350px;
  }
  .table-wrapper .table-body {
    max-height: calc(100vh - 185px);
  }
  .table-wrapper .table-body .row span {
    font-size: 14px;
  }
}
