@import "@styles/variables";

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  box-sizing: border-box;
}
.radio-line {
  display: flex;
  gap: 1px;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .radio-label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 35px;
    margin-right: 15px;
    font-size: 18px;
    line-height: 20px;
  }

  .radio-label:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 17px;
    position: absolute;
    left: 2.78px;
    bottom: 3.77px;
    background-color: #fff;
    border-radius: 50%;
    transition: all 0.3s;
    z-index: 1;
  }
  .radio-label::after {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    bottom: 1px;
    background-color: #fff;
    border: 2px solid $text-color-light;
    border-radius: 50%;
    transition: all 0.3s;
  }

  input[type="radio"]:checked + .radio-label:before {
    content: "";

    background-color: $text-color-light;
    // border-color: rgb(164, 58, 58);
    transition: all 0.3s;
  }
}

@media screen and (max-width: 1024px) {
  .radio-line .radio-label {
    font-size: 14px;
  }
}
