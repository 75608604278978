@import "@styles/variables";

.modal-user {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 28px 20px 20px 20px;
  width: 600px;
  @media screen and (max-width: "1200px") {
    width: 100%;
  }
  @media screen and (max-width: "700px") {
    padding: 0;
    gap: 15px;
  }

  h1 {
    align-self: center;
    margin: 0;
    text-align: center;
    font-size: 26px;
    font-weight: 400;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 14px;

    div {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 0.3fr 1fr;
      align-items: center;
      gap: 20px;
      position: relative;
      @media screen and (max-width: "700px") {
        grid-template-columns: 1fr;
        grid-template-rows: 0.5fr 1fr;
        gap: 5px;
      }

      span {
        color: $text-color;
        font-size: 16px;
      }
      input {
        border: 1px solid $button-disabled-color;
        border-radius: 10px;
        padding: 16px 10px;
        outline: none;
        font-size: 14px;
        box-sizing: border-box;
        height: 43px;
        &::placeholder {
          color: $placeholder-color;
        }
        &.input-error {
          border-color: rgba(255, 0, 0, 0.425);
        }
      }
      .error {
        display: flex;
        position: absolute;
        top: 41px;
        right: 10px;
        @media screen and (max-width: "700px") {
          top: 71px;
        }

        .line-error {
          font-size: 10px;
          color: rgba(255, 0, 0, 0.468);
        }
      }
      .checkbox-wrapper {
        padding-left: 1px;
      }
    }

    .photo {
      padding-left: 1px;
      img {
        width: 52px;
        height: 52px;
        cursor: pointer;
      }
    }
  }
  .body.hidden {
    scale: 0;
    transition: all 1s;
  }
  .body.hidden.active {
    scale: 1;
    transition: all 1s;

    .select-wrapper {
      display: flex !important;
      position: relative;
      width: 100%;
      height: 46px;
      .select-body {
        height: 46px;
        .select {
          padding: 12px 10px;
          height: 46px;
        }
        .items {
          height: 110px;
          span {
            height: initial;
          }
        }
      }
    }
  }
  .select-departments {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  button {
    width: 100%;
    text-align: center;
    color: $white-color;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    font-weight: 700;
    background-color: #297952;
    margin-top: 30px;
  }
}
