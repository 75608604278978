@import "@styles/variables";

.control-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 7px;
  padding: 15px 50px 19px 25px;
  box-sizing: border-box;

  .group {
    display: flex;
    align-items: flex-end;
    gap: 20px;
  }
  .group-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  h1 {
    font-size: 28px;
    color: $text-color;
    font-weight: 400;
    margin: 0;
  }
  .btn-btn {
    min-width: 210px;

    button {
      width: 100%;
      border-radius: 10px;
      border: none;
      color: $white-color;
      font-size: 16px;
      font-weight: 700;
      padding: 9px 20px;
    }
  }

  .filter-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    min-height: 43px;
    min-width: 150px;
    padding: 1px 18px 1px 7px;
    background-color: $white-color;
    border: 1px solid $button-disabled-color;
    border-radius: 10px;
    box-sizing: border-box;
    input {
      background: none;
      outline: none;
      border: none;
      font-size: 16px;
      font-weight: 400;
      color: $text-color;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .filter-btn {
    width: 139px;

    button {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 14px;
      background-color: $main-color;
      border-radius: 10px;
      border: none;
      color: $white-color;
      span {
        width: 100%;
        font-size: 16px;
      }
      &:hover {
        background-color: $button-hover-color;
      }
      &:active {
        background-color: $button-active-color;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .control-wrap {
    padding: 15px 20px;
  }
  .control-wrap .filter-input input {
    width: 200px;
  }
  .auth-wraper .auth-content .modal-user {
    gap: 30px;
    padding: 0px 0px;
  }
  .auth-wraper .auth-content .body .error.login .line-error {
    font-size: 10px;
    left: 0px;
  }
  .table-wrapper .table-body .row span {
    font-size: 14px;
  }
}

@media screen and (max-width: 760px) {
  .control-wrap h1 {
    font-size: 24px;
  }
  .control-wrap {
    padding: 15px 10px;
  }
}

@media screen and (max-width: 620px) {
  .control-wrap .filter-input input {
    width: 100px;
  }
}
