.ListGroups-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.ListGroups-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:
        opacity 300ms,
        transform 300ms;
}
.ListGroups-exit {
    transform: translateX(0%);
    opacity: 1;
}
.ListGroups-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition:
        opacity 300ms,
        transform 300ms;
}

.itemGroup-enter {
    opacity: 0;
    transform: scale(0.9);
    transform: translateX(-20%);
}
.itemGroup-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:
        opacity 300ms,
        transform 500ms;
}
.itemGroup-exit {
    opacity: 1;
    transform: translateX(0);
}
.itemGroup-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transform: translateX(20%);
    transition:
        opacity 300ms,
        transform 500ms;
}
