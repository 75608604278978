@import "@styles/variables";

.calendar-reports {
  display: flex;
  width: 250px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: $white-color;
  padding: 0px;
  max-height: 400px;
  height: 337px;
  padding: 5px;
  overflow: hidden;
  transition: all 0.5s ease;
  z-index: 9;
  div {
    display: block;
  }
  .react-calendar {
    contain: strict;
    width: 100%;
    background: $white-color;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    border: 0px;
    border-radius: 14px;
    color: $text-color;

    .react-calendar__month-view__days__day {
      color: $text-color;
    }
    .react-calendar__tile--active {
      background-color: $greenCalendar;
      color: $white-color !important;
    }
    .react-calendar__month-view__days__day--weekend {
      color: $text-color;
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: $grey300;
    }

    .react-calendar__navigation {
      display: flex;
      box-sizing: border-box;
      margin-bottom: 0;
    }
    .react-calendar__navigation button {
      min-width: inherit;
    }
    .react-calendar__navigation__label {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: initial;
      padding-bottom: 5px;
      color: $text-color;
      &:hover {
        background-color: transparent;
      }
    }
    .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
      display: flex;
      width: 1px;
      padding: 0;
      &:hover {
        background-color: transparent;
      }
    }
    .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-sizing: border-box;
      margin-left: 30px;
      &:hover {
        background-color: $grey100;
      }
    }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: transparent;
    }
    .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
      display: flex;
      width: 1px;
      padding: 0;
      &:hover {
        background-color: transparent;
      }
    }
    .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 30px;
      box-sizing: border-box;
      &:hover {
        background-color: transparent;
      }
    }

    .calendar-date {
      text-align: center;
    }
    abbr[title] {
      text-decoration: none;
    }

    .react-calendar__month-view__weekdays__weekday {
      color: $grey300;
      font-size: 14px;
      text-decoration: none !important;
    }
    .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
      font-size: 18px;
      font-weight: 700;
      font-family: "Roboto";
    }
    .react-calendar__month-view__days {
      gap: 0 5px;
    }

    .react-calendar__tile.react-calendar__month-view__days__day.content {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 30px;
      max-height: 30px;
      padding: 0;
      border-radius: 50%;
    }
    button {
      min-height: 30px;
    }
    .content {
      font-size: 14px;
    }
    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
      padding: 1.2em 0.5em;
      height: inherit;
      &:hover {
        border-radius: 20px;
      }
    }
    .react-calendar__tile--hasActive {
      border-radius: 20px;
      width: 50px;
      background: $greenCalendar;
    }
  }
}
