@import "@styles/variables";

.select-wrapper {
  display: flex !important;
  width: 100%;
  flex: 1;

  div {
    display: flex !important;
  }

  .select-body {
    flex-direction: column;
    width: 100%;

    border: 1px solid transparent;
    top: 0;
    box-sizing: border-box;

    .select {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $button-disabled-color;
      border-radius: 10px;
      padding: 10px 10px;
      cursor: pointer;
      box-sizing: border-box;
      .wrap-img {
        width: 26px;
        height: 22px;
        display: flex;
        align-items: center;
        img {
          width: 17px;
          height: 9px;
        }
      }
      span {
        font-size: 16px;
        color: $text-color;
      }
    }
    .items-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 5px;
      overflow-y: hidden;
      max-height: 0px;
      min-height: 0px;
      transition: all 0.5s ease;
      background: $white-color;
      border-radius: 10px;
      overflow: auto;
      &.active {
        min-height: 150px;
        max-height: 150px;
        transition: all 0.5s ease;
        overflow: auto;
        border: 1px solid $button-disabled-color;
      }
      .list-scrolled {
        display: flex;
        flex-direction: column;
        top: 0px;
        left: 0;
        width: 100%;
      }
      span {
        display: flex;
        width: 100%;
        padding: 6px 10px;
        cursor: pointer;
        font-size: 16px;
        color: $text-color;
        box-sizing: border-box;

        &:hover {
          background-color: $select-hover-color;
        }

        &:active {
          background-color: $text-table-color;
          color: $white-color;
        }
      }
    }
    &.active {
      display: flex;
      background: $white-color;
      border-radius: 10px;
      gap: 7px;
    }
  }
}
