@import "@styles/variables";

.auth_wrapper {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: $bg-color;

  .auth_content {
    display: flex;
    background-color: $white-color;
    padding: 3px 24px;
    border-radius: 20px;
    .modal_user {
      display: flex;
      flex-direction: column;
      gap: 28px;
      padding: 28px 30px 20px 30px;
      transition: 0.5s all;
      h1 {
        text-align: center;
        width: 388px;
        margin: 0;
        font-size: 26px;
        font-weight: 400;
      }
    }
    .body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      div {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        position: relative;
      }
      input {
        width: 251px;
        border: 1px solid $button-disabled-color;
        border-radius: 10px;
        height: 43px;
        font-size: 14px;
        padding: 10px 10px;
        box-sizing: border-box;
        outline: none;
      }
      .input_error {
        border-color: rgba(255, 0, 0, 0.425);
      }
      .error {
        display: flex;
        position: absolute;
        width: 251px;
        top: 42px;
        right: 0px;

        &.login {
          font-size: 16px;
          top: 48px;
          left: 170px;

          .line_error {
            font-size: 12px;
          }
          &.code {
            left: 130px;
          }
        }

        .line_error {
          font-size: 12px;
          color: rgba(255, 0, 0, 0.768);
          line-height: 14px;
        }
      }
      .row {
        display: grid;
        min-height: 92px;
        grid-template-columns: 5% 15% 20% 15% 10% 20% 5%;
        align-items: center;
        .avatar {
          display: flex;
          align-items: center;
          justify-self: center;
          img {
            width: 40px;
            height: 40px;
          }
        }
        .dates {
          display: flex;
          flex-direction: column;
        }
      }
      span {
        text-align: left;
        font-size: 16px;
        color: #274335;
      }
    }
    .repeat_text {
      display: flex;
      font-size: 16px;
      justify-content: center;
    }
    button {
      width: 100%;
      text-align: center;
      color: $white-color;
      border-radius: 10px;
      border: none;
      font-size: 16px;
      font-weight: 700;
      min-height: 43px;
      background-color: $main-color;
      &:hover {
        background-color: $button-hover-color;
      }
      &:active {
        background-color: $button-active-color;
      }
      &:disabled {
        background-color: $button-disabled-color;
        cursor: none;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .auth_wrapper .auth_content .body input {
    width: 220px;
  }
  .auth_wrapper .auth_content .body .error {
    width: 220px;
  }
  .auth_wrapper .auth_content .modal_user {
    gap: 30px;
    padding: 10px 10px;
    h1 {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 710px) {
  .auth_wrapper .auth_content .body span {
    font-size: 14px;
  }
  .auth_wrapper .auth_content .body input {
    width: 200px;
  }
  .auth_wrapper .auth_content .body div {
    gap: 20px;
  }
  .auth_wrapper .auth_content .body .error .line_error {
    font-size: 10px;
  }
  .auth_wrapper .auth_content .body .error {
    width: 200px;
    top: 42px;
  }
  .auth_wrapper .auth_content {
    padding: 20px 24px;
  }
  .auth_wrapper .auth_content .repeat_text {
    font-size: 14px;
  }

  .auth_wrapper .auth_content .modal_user {
    gap: 23px;
    padding: 0px 0px;
    h1 {
      font-size: 20px;
      width: 250px;
    }
  }
}

@media screen and (max-width: 550px) {
  .auth_wrapper .auth_content {
    padding: 15px 15px;
    background-color: $bg-color;
  }
  .auth_wrapper .auth_content .body {
    background-color: $bg-color;
  }
  .auth_wrapper .auth_content .body span {
    font-size: 14px;
  }
  .auth_wrapper .auth_content .body div {
    background-color: $bg-color;
    gap: 10px;
  }
  .auth_wrapper .auth_content .body .error {
    top: 43px;
  }
  .auth_wrapper .auth_content .body .error .line_error {
    font-size: 9px;
  }
  .auth-wrapper .auth-content .repeat-text {
    font-size: 14px;
  }
  .modal_user button {
    min-height: 43px;
    font-size: 14px;
  }
  .auth_wrapper .auth_content .body input {
    width: 200px;
    font-size: 16px;
    height: 43px;
  }
  .auth_wrapper .auth_content button {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .auth_wrapper .auth_content .modal_user {
    h1 {
      font-size: 20px;
    }
  }
  .auth_wrapper .auth_content .body input {
    width: 180px;
    font-size: 14px;
    height: 43px;
  }
  .auth_wrapper .auth_content .body span {
    font-size: 10px;
  }

  .auth_wrapper .auth_content .body div {
    gap: 10px;
  }
  .auth_wrapper .auth_content .body .error {
    width: 180px;
  }
  .auth_wrapper .auth_content .repeat_text {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .auth_wrapper .auth_content .modal_user {
    h1 {
      font-size: 14px;
      width: inherit;
    }
  }
  .auth_wrapper .auth_content .body input {
    width: 150px;
    font-size: 12px;
  }
  .auth_wrapper .auth_content {
    padding: 20px 4px;
  }
  .auth_wrapper .auth_content .body .error {
    width: 150px;
  }
  .auth_wrapper .auth_content .body .error .line_error {
    font-size: 8px;
  }


}
