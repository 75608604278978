@import "@styles/variables";

.modal-admin-passw {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 28px 50px 20px 50px;
  h1 {
    align-self: center;
    margin: 0;
    width: 480px;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
  }
  .body {
    display: grid;
    grid-template-rows: 1fr 0.9fr;
    grid-gap: 25px;
    div {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 0.49fr 1fr;
      align-items: center;
      gap: 20px;
      position: relative;

      span {
        color: $text-color;
        font-size: 16px;
      }

      input {
        // width: 351px;
        border: 1px solid $button-disabled-color;
        border-radius: 10px;
        padding: 6px 10px;
        height: 43px;
        outline: none;
        font-size: 14px;
        box-sizing: border-box;

        &::placeholder {
          color: $placeholder-color;
        }
        &.input-error {
          border-color: rgba(255, 0, 0, 0.498);
        }
      }
      .error {
        display: flex;
        position: absolute;
        top: 6.2vh;
        left: 175px;

        .line-error {
          font-size: 10px;
          color: rgba(255, 0, 0, 0.468);
        }
      }
    }
  }
  button {
    width: 100%;
    text-align: center;
    color: $white-color;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    font-weight: 500;
    background-color: #297952;
  }
}

@media screen and (max-width: 870px) {
  .modal-admin-passw h1 {
    width: inherit;
  }
}
