$button-disabled-color: #e3e9e7;
$button-active-color: #185035;
$button-hover-color: #33c37d;
$select-hover-color: #ecf0ef;
$navbar-hover-color: #dfd8ff;
$placeholder-color: #b3beb8;
$text-table-color: #5f6e67;
$text-color-light: #219653;
$blue-line-color: #007aff;
$scroll-color: #297952b7;
$bg-base-color: #f5f5f5;
$bg-graf-color: #ecf0ef;
$switch-color: #30d158;
$yellow-color: #ffb800;
$white-color: #ffffff;
$error-color: #ff3b30;
$main-color: #297952;
$text-color: #274335;
$bg-color: #f5f9f8;
$grey100: #e6e6e6;
$grey300: #c7c7c7;
$greenCalendar: #a1b6ad;
