@import "@styles/variables";

.select-line {
  flex-direction: column;
  position: relative;
  top: 0;

  .select {
    display: flex;
    width: 8.44vw;
    min-width: 136px;
    height: 43px;
    max-height: 56px;
    justify-content: space-between;
    align-items: center;
    background-color: $white-color;
    border: 1px solid $placeholder-color;
    border-radius: 10px;
    padding: 9px 10px 6px;
    cursor: pointer;
    box-sizing: border-box;
    &.line {
      min-width: 200px;
      align-items: center;
      padding: 6px 10px;
    }
    &.active {
      background-color: $select-hover-color;
      cursor: inherit;
    }
    span {
      font-size: 16px;
      color: $text-color;
    }
    img {
      width: 17px;
      height: 9px;
    }
  }
  .items {
    display: flex;
    flex-direction: column;
    top: 47px;
    left: 0;
    width: 100%;
    gap: 1px;
    position: absolute;
    background: $white-color;
    border: 1px solid $placeholder-color;
    border-radius: 10px;
    z-index: 11;
    .row-line {
      display: flex;
      align-items: center;
      padding-left: 10px;
      height: 38px;
    }

    span {
      display: flex;
      width: 100%;
      padding: 6px 10px;
      cursor: pointer;
      font-size: 16px;
      color: $text-color;
      box-sizing: border-box;

      &:hover {
        background-color: $select-hover-color;
      }

      &:active {
        background-color: $text-table-color;
        color: $white-color;
      }
    }
  }
  &.active {
    display: flex;
    background: $white-color;
    border-top: none;
    border-radius: 10px;
    gap: 0;
    z-index: 999;
  }
}

@media screen and (max-width: 750px) {
  .select-line .select span {
    font-size: 14px;
  }
}
