@import "@styles/variables";

.picker-wrapper {
  width: 100%;
  div {
    box-sizing: border-box;
  }

  .select-body {
    flex-direction: column;
    width: 100%;
    top: 0;

    .select {
      display: flex;
      width: 100%;
      height: 53px;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $button-disabled-color;
      border-radius: 10px;
      padding: 16px 10px;
      cursor: pointer;
      box-sizing: border-box;

      span {
        font-size: 16px;
        color: $text-color;
      }

      img {
        width: 17px;
        height: 9px;
      }
    }
    .items {
      display: flex;
      flex-direction: column;
      top: 0px;
      left: 0;
      width: 100%;
      gap: 1px;

      span {
        display: flex;
        width: 100%;
        padding: 6px 10px;
        cursor: pointer;
        font-size: 16px;
        color: $text-color;
        box-sizing: border-box;

        &:hover {
          background-color: $select-hover-color;
        }

        &:active {
          background-color: $text-table-color;
          color: $white-color;
        }
      }
    }
    &.active {
      display: flex;
      background: $white-color;
      border-top: none;
      border-radius: 10px;
      gap: 0;
      z-index: 999;
    }
    .calendar-wrapper {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      width: 560px;
      height: 330px;

      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease;
      @media screen and (max-width: "1200px") {
        right: inherit;
        left: 20px;
        width: 600px;
      }
      @media screen and (max-width: "700px") {
        height: 380px;
        left: 0px;
        top: 50px;
        width: 330px;
      }
      &.open {
        max-height: 400px;
        transition: all 0.5s ease 0.5s;
      }

      button {
        border-radius: 10px;
        border: none;
        font-size: 18px;
        font-weight: 400;
        color: $text-color;
      }
      div {
        display: block;
      }
      .react-calendar {
        contain: strict;
        width: 100%;
        background: $white-color;
        border: 1px solid #a0a096;
        font-family: Roboto, Arial, Helvetica, sans-serif;
        line-height: 1.125em;
        border: 0px solid #a0a096;
        border-radius: 14px;
        color: $text-color;

        .react-calendar__tile--active {
          background-color: #33c37d85;
        }

        .react-calendar__navigation {
          display: flex;
          height: 44px;
          margin-bottom: 1em;
        }
      }
    }
  }
}
