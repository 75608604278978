@import "@styles/variables";

.select-date {
  display: flex;
  div {
    box-sizing: border-box;
  }

  .select-body {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    position: relative;

    .select {
      display: flex;
      width: 8.44vw;
      min-width: 140px;
      height: 43px;
      max-height: 56px;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      border: 1px solid $placeholder-color;
      border-radius: 10px;
      padding: 9px 10px 6px;
      cursor: pointer;
      box-sizing: border-box;
      gap: 7px;

      span {
        font-size: 16px;
        line-height: 24px;
        color: $text-color;
      }

      img {
        width: 17px;
        height: 9px;
      }
      &.simple {
        border: 0px solid $placeholder-color;
        width: 167px;
        justify-content: flex-start;
        span {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    .items {
      display: flex;
      flex-direction: column;
      top: 0px;
      left: 0;
      width: 100%;
      gap: 1px;

      span {
        display: flex;
        width: 100%;
        padding: 6px 10px;
        cursor: pointer;
        font-size: 16px;
        color: $text-color;
        box-sizing: border-box;

        &:hover {
          background-color: $select-hover-color;
        }

        &:active {
          background-color: $text-table-color;
          color: $white-color;
        }
      }
    }
    &.active {
      display: flex;
      background: $white-color;
      border-top: none;
      border-radius: 10px;
      gap: 0;
      z-index: 9;
    }

    .calendar-wrapper {
      position: absolute;
      top: 47px;
      display: flex;
      width: 352px;
      border-radius: 10px;
      height: 337px;
      box-sizing: border-box;
      background-color: #fff;
      padding: 0px;
      border: 0px solid $placeholder-color;
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease;
      z-index: 9;
      &.open {
        max-height: 400px;
        height: 337px;
        transition: all 0.5s ease 0.3s;
        padding: 5px;
        border: 1px solid $placeholder-color;
      }
      &.right {
        left: 0px;
      }
      &.left {
        right: 0px;
      }
      div {
        display: block;
      }
      .react-calendar {
        contain: strict;
        width: 100%;
        background: $white-color;
        border: 1px solid #a0a096;
        font-family: Roboto, Arial, Helvetica, sans-serif;
        line-height: 1.125em;
        border: 0px solid #a0a096;
        border-radius: 14px;
        color: $text-color;

        .react-calendar__month-view__days__day {
          color: $text-color;
        }
        .react-calendar__tile--active {
          background-color: #a1b6ad;
          color: #fff !important;
        }
        .react-calendar__month-view__days__day--weekend {
          color: $text-color;
        }
        .react-calendar__month-view__days__day--neighboringMonth {
          color: #c7c7c7;
        }

        .react-calendar__navigation {
          display: flex;
          padding-top: 10px;
          height: initial;
          box-sizing: border-box;
          margin-bottom: 0;
        }
        .react-calendar__navigation__label {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-grow: initial;
          padding-bottom: 5px;
          color: #274335;
          &:hover {
            background-color: transparent;
          }
        }
        .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
          display: flex;
          width: 1px;
          padding: 0;
          &:hover {
            background-color: transparent;
          }
        }
        .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          box-sizing: border-box;
          margin-left: 30px;
          &:hover {
            background-color: #e6e6e6;
          }
        }
        .react-calendar__navigation button:enabled:hover,
        .react-calendar__navigation button:enabled:focus {
          background-color: transparent;
        }
        .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
          display: flex;
          width: 1px;
          padding: 0;
          &:hover {
            background-color: transparent;
          }
        }
        .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 30px;
          box-sizing: border-box;
          &:hover {
            background-color: transparent;
          }
        }

        .calendar-date {
          text-align: center;
        }
        abbr[title] {
          text-decoration: none;
        }

        .react-calendar__month-view__weekdays__weekday {
          color: #c7c7c7;
          font-size: 14px;
          text-decoration: none !important;
        }
        .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
          font-size: 18px;
          font-weight: 700;
          font-family: "Roboto";
        }
        .react-calendar__month-view__days {
          gap: 0 10px;
        }

        .react-calendar__tile.react-calendar__month-view__days__day.content {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 40px;
          height: 40px;
          padding: 0;
          border-radius: 50%;
        }
        .content {
          font-size: 18px;
        }
        .react-calendar__year-view .react-calendar__tile,
        .react-calendar__decade-view .react-calendar__tile,
        .react-calendar__century-view .react-calendar__tile {
          padding: 1.2em 0.5em;
          height: inherit;
          &:hover {
            border-radius: 20px;
          }
        }
        .react-calendar__tile--hasActive {
          border-radius: 20px;
          width: 50px;
          background: #a1b6ad;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .select-date .select-body .select span {
    font-size: 14px;
  }
}
@media screen and (max-width: 750px) {
  .select-date .select-body .select span {
    font-size: 12px;
  }
  .select-date .select-body .select {
    min-width: 130px;
  }
}
