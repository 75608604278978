@import "@styles/variables";

.navLink {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 110px;
  height: 100%;
  text-decoration: none;
  font-size: 16px;
  color: $text-color;
  border-bottom: 2px solid transparent;
  transition: background-color 0.5s;
  &:hover {
    transition: background-color 0.5s linear;
    background-color: $button-disabled-color;
    border-radius: 3px;
  }
  &.active {
    transition: all 1.5s ease-out;
    border-bottom: 2px solid $main-color;
    color: $main-color;
  }
}

@media screen and (max-width: 849px) {
  .navLink {
    width: 100%;
    height: 31px;
    justify-content: start;
    padding-left: 10px;
  }
}
