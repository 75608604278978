@import "@styles/variables";

.others-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
  min-width: 810px;
  overflow-x: auto;
  div {
    box-sizing: border-box;
  }
  .others {
    display: flex;
    flex-direction: column;
    gap: 1px;
    margin-bottom: 2px;
    flex: 1;
    &-header {
      display: grid;
      grid-template-columns: 16% 28% 18% 20% 12% 6%;
      grid-template-rows: 35px;
      align-items: center;
      background-color: $white-color;
      padding: 4px 0px 4px 20px;

      div {
        justify-self: start;
        color: $text-table-color;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex: 1;
      max-height: calc(100vh - 177px);
      overflow-y: auto;
      position: relative;
      background-color: $white-color;
      border: 1px solid $button-disabled-color;
      scrollbar-color: $scroll-color $select-hover-color;
      scrollbar-width: thin;
      min-height: 300px;
      .row {
        display: grid;
        min-height: 77px;
        grid-template-columns: 16% 28% 18% 20% 12% 6%;
        align-items: center;
        position: relative;
        padding: 0 0px 0 20px;
        font-size: 16px;
        span {
          text-align: left;
        }
        .check-input {
          position: absolute;
          opacity: 0;
          width: 1.25em;
          height: 1.25em;
          margin: 0;
          cursor: pointer;
          &:checked + .check::before {
            background: url("../../../../../assets/icons/Vector.svg");
            background-position: center;
          }
          &:hover + .check::before {
            border: 1px solid $button-disabled-color;
          }
        }
        .check {
          display: inline-flex;
          align-items: center;
          user-select: none;
          &:before {
            content: "";
            display: inline-block;
            width: 1em;
            height: 1em;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1px solid $button-hover-color;
            border-radius: 2px;
            background-color: $white-color;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
          }
        }
        .tooltip {
          display: flex;
          flex-direction: column;
          position: absolute;
          right: 45px;
          top: -17px;
          border-radius: 10px;
          box-sizing: border-box;
          overflow: hidden;
          background-color: $white-color;
          scale: 0;
          transition: all 0.4s;
          -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
          -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
          box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
          z-index: 3;

          &.active {
            scale: 1;
            transition: all 0.4s;
          }
          &-item {
            display: flex;
            justify-items: center;
            align-items: center;
            gap: 15px;
            box-sizing: border-box;
            width: 267px;
            height: 43px;
            padding: 6px 10px 6px 14px;
            &:hover {
              background-color: $select-hover-color;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .others-wrapper {
    width: calc(100vw - 50px);
    overflow-x: auto;
    min-width: inherit;
    @media screen and (max-width: 700px) {
      width: 100vw;
    }
  }
  .others {
    width: 850px;
  }
  .others-wrapper .others-body {
    max-height: calc(100vh - 185px);
  }
}
