@import "@styles/variables";

.stats-container {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    min-width: 650px;
  }
  div {
    display: flex;
  }
  h1 {
    margin: 0;
    padding-bottom: 5px;
    font-size: 26px;
  }

  .users-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 25px 30px 5px 30px;
    @media screen and (max-width: 800px) {
      padding: 30px 16px;
    }
    .users-title {
      flex-direction: column;
      padding: 30px 0 10px;
      &.second {
        padding-bottom: 8px;
      }
    }
    h2 {
      font-size: 20px;
      width: 393px;
      margin: 0;
    }

    .graf-container {
      display: flex;
      flex-grow: 1;
      gap: 60px 4%;
      // padding: 0 60px 0 30px;
      position: relative;
      .graf-task {
        display: flex;
        flex-direction: column;
        width: 100%;
        .users-header {
          flex-wrap: wrap;
          width: 100%;
          justify-content: space-between;
          align-items: flex-end;
          gap: 5px 20px;
          padding-bottom: 20px;
          &.goals {
            justify-content: initial;
          }

          .check-wrap {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-right: 20px;
            .check-line {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
          .data-wrap {
            flex-wrap: wrap;
            flex-grow: 1;
            justify-content: end;
            gap: 10px;
            align-items: center;
            position: relative;
            button {
              min-height: 40px;
            }
            &.left {
              justify-content: start;
              flex-wrap: nowrap;
            }
            &.planed {
              height: 48px;
              font-size: 15px;
              .into-planed {
                gap: 15px;
                width: 100%;
                span {
                  width: 160px;
                }
              }
            }
            .task-wrap {
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }
        }
      }

      .graf-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 357px;
        background-color: $white-color;
        padding: 3px 5px 5px 0;
        box-sizing: border-box;
        border-radius: 20px;
        position: relative;
        .task-wrap {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          gap: 10px;
        }
        .task-item {
          display: flex;
          justify-content: space-between;

          border-bottom: 1px solid $button-disabled-color;
          padding: 0 0 10px 0px;
        }
        .business-task {
          display: flex;
          gap: 10px;
          align-items: center;
        }
        .goals {
          display: flex;
          align-items: center;
          gap: 20px;

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 47px;
            height: 47px;
            border: 1px solid $button-disabled-color;
            border-radius: 47px;
          }
        }
        .value {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 38px;
          height: 34px;
          background-color: $bg-color;
          font-weight: 700;
        }
        .pie-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          padding-left: 20px;
          .pie-stats {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            max-width: 370px;
            gap: 10px;
            font-size: 16px;
            .pie-info {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 7px;
              width: 100%;
              span {
                font-weight: 700;
              }
              &.red {
                color: $error-color;
              }
              &.blue {
                color: $blue-line-color;
              }
            }
          }
        }
      }
    }
  }
  .users-wrap.tasks {
    display: flex;
    padding: 60px 30px 60px 30px;
    @media screen and (max-width: 800px) {
      padding: 30px 16px;
    }
    .title-dep {
      justify-content: space-between;
      .task-filter {
        display: flex;
        gap: 16px;
        padding-left: 15px;
        .check-filter {
          display: flex;
        }
      }
    }
    .graf-container {
      flex-wrap: wrap;
      .graf-task {
        display: flex;
        flex-direction: column;
        width: 48%;
        .users-header {
          .data-wrap {
            .empty {
              display: flex;
              height: 48px;
              min-width: 230px;
            }
            .selectline {
              justify-content: end;
              flex: 1;
            }
            .selectdate {
              display: flex;
              align-items: center;
              gap: 7px;
            }
            .task-wrap {
              display: flex;
              gap: 5px;
              align-items: center;
              span {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
  .users-wrap.tasks.goals {
    background-color: #f5f5f5;
  }
}

@media screen and (max-width: 1169px) {
  .stats-container .users-wrap.tasks .graf-container .graf-task {
    width: 100%;
  }
  .stats-container .users-wrap .graf-container .graf-task .users-header .data-wrap.planed .into-planed {
    justify-content: end;
  }
  .stats-container .users-wrap .graf-container .graf-task .users-header {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 1024px) {
  .stats-container h1 {
    font-size: 22px;
  }
  .stats-container .users-wrap h2 {
    font-size: 18px;
  }
  .layout {
    font-size: 14px;
  }
  .stats-container .users-wrap.tasks .graf-container .graf-task .users-header .data-wrap .task-wrap span {
    font-size: 14px;
  }
}
